import { defineStore } from 'pinia';
import { type LooseObject } from '@/types/generic';
import { type ProductsList } from '@/types/products';
import productList from '@/lib/products';
import { handleError } from '~/utils/errors';

interface StripeProductsResponse {
  object: string;
  url: string;
  has_more: boolean;
  data: StripeProducts;
}

export interface StripeProductPrice extends LooseObject {
  id: string;
  unit_amount: number;
  currency: string;
  recurring: {
    interval: string;
    interval_count: number;
  };
}

export interface StripeProduct extends LooseObject {
  id: string;
  images: string[];
  name: string;
  prices: StripeProductPrice[];
  active: boolean;
  type: string;
}

export type StripeProducts = StripeProduct[];

interface State {
  stripeProducts: StripeProducts;
  products: ProductsList;
  isLoading: boolean;
  isLoaded: boolean;
}

export const useProducts = defineStore('products', {
  state: (): State => ({
    stripeProducts: [],
    products: productList(),
    isLoading: false,
    isLoaded: false,
  }),
  getters: {
    plans(state) {
      return state.products.plans;
    },
    supportPlans(state) {
      return state.products.supportPlans;
    },
    terms(state) {
      return state.products.terms;
    },
    setup(state) {
      return state.products.setup;
    },
  },
});
