import type { ProductsList } from '@/types/products';
import { useRuntimeConfig } from 'nuxt/app';

const productList = (): ProductsList => {
  const inProduction = useRuntimeConfig().public.stripeProducts === 'live';

  return {
    promo: [
      {
        name: 'signup',
        funnelSubtitle: 'Get up to 6 months half price*',
      },
      {
        name: 'bbc',
        funnelSubtitle: 'BBC Funnel Description',
        funnelDescription: 'Join before 30th June. T&Cs apply.',
      },
    ],

    plans: [
      {
        id: 1,
        planKey: 'fibre-starter',
        name: 'Fibre Starter',
        stripeCode: inProduction ? 'prod_McyLYo4dlt8EWI' : 'prod_McyNN8fryola0h',
        stripePriceCode: inProduction ? 'price_1LtiObLyen3md8eT6FWqTFhK' : 'price_1LtiR3Lyen3md8eT5OSBD2KJ',
        stripeNoTermPriceCode: inProduction ? 'price_1NTGwKLyen3md8eTa5FASlIs' : 'price_1NTHIaLyen3md8eTH0HJYWa3',
        price: 6499,
        noTermPrice: 6999,
        noTermDiscountPrice: 5949,
        speed: {
          download: 52,
          upload: 10,
        },
        gaugeFill: 33,
        householdSize: 2,
        promo: {
          bbc: {
            price: 3249,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          signup: {
            price: 3249,
            stripePriceCode: '',
            benefit: '4 months half price',
            description: 'Half price for the first 4 months*',
          },
          compass: {
            price: 3249,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          '5month': {
            price: 3249,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          '6month': {
            price: 3249,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 4 months*',
          },
        },
      },
      {
        id: 2,
        name: 'Fibre Essentials',
        planKey: 'fibre-essentials',
        stripeCode: inProduction ? 'prod_McyKLdzRrRRlvQ' : 'prod_McyNg0RDqDTtWa',
        stripePriceCode: inProduction ? 'price_1PkJVgLyen3md8eTNNRwNCWN' : 'price_1LtiQeLyen3md8eTApbg6Eec',
        stripeNoTermPriceCode: inProduction ? 'price_1Pke86Lyen3md8eTsj61tpNe' : 'price_1NTHJ9Lyen3md8eTfRaluVKd',
        price: 9499,
        noTermPrice: 10499,
        noTermDiscountPrice: 8899,
        speed: {
          download: 312,
          upload: 100,
        },
        gaugeFill: 60,
        householdSize: 4,
        isMostPopular: true,
        promo: {
          bbc: {
            price: 4749,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          signup: {
            price: 4749,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          compass: {
            price: 4749,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          '5month': {
            price: 4749,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          '6month': {
            price: 4749,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
        },
      },
      {
        id: 3,
        name: 'Fibre Max',
        planKey: 'fibre-max',
        stripeCode: inProduction ? 'prod_McyK6iKLfuK34s' : 'prod_McyN9LVzVF57Zr',
        stripePriceCode: inProduction ? 'price_1PkJXRLyen3md8eTvIMDOQuA' : 'price_1LtiQoLyen3md8eTawHUABrw',
        stripeNoTermPriceCode: inProduction ? 'price_1Pke9dLyen3md8eTLeABvRNe' : 'price_1NTHJgLyen3md8eTDwlpbX0k',
        price: 10499,
        noTermPrice: 11499,
        noTermDiscountPrice: 9799,
        speed: {
          download: 878,
          upload: 500,
        },
        gaugeFill: 80,
        householdSize: 6,
        promo: {
          bbc: {
            price: 5249,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          signup: {
            price: 5249,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          compass: {
            price: 5249,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          '5month': {
            price: 5249,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          '6month': {
            price: 5249,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
        },
        // promoNoTerm: {
        //   student: {
        //     price: 9349,
        //     stripePriceCode: '',
        //     benefit: '15% discount for Student Card',
        //   },
        // },
      },
      {
        id: 4,
        name: 'Hyperfibre 2000',
        planKey: 'fibre-hyper',
        stripeCode: inProduction ? 'prod_NWlzCJRNAwwPpv' : 'prod_OhTVbfHdzu6yBs',
        stripePriceCode: inProduction ? 'price_1PkJYmLyen3md8eTYmOYvIMU' : 'price_1Nu4YdLyen3md8eTeYb5Fvih',
        stripeNoTermPriceCode: inProduction ? 'price_1NTHUmLyen3md8eTVvvZ1Xwi' : 'price_1Nu4YdLyen3md8eT1CuIW9DK',
        price: 15999,
        noTermPrice: 16999,
        // noTermDiscountPrice: 14449,
        speed: {
          download: 2000,
          upload: 2000,
        },
        hyper: true,
        gaugeFill: 100,
        householdSize: 10,
        promo: {
          bbc: {
            price: 7999,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          signup: {
            price: 7999,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          '5month': {
            price: 7999,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          '6month': {
            price: 7999,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
        },
      },
      {
        id: 4,
        name: 'Hyperfibre 4000',
        planKey: 'fibre-hyper-4000',
        stripeCode: inProduction ? 'prod_QCUWxhWqYjzzFZ' : 'prod_QCUWxhWqYjzzFZ',
        stripePriceCode: inProduction ? 'price_1PkJZcLyen3md8eTsrTupiZF' : 'price_1PM5X7Lyen3md8eT9AiLo14h',
        stripeNoTermPriceCode: inProduction ? 'price_1POXovLyen3md8eTXreKrt5w' : 'price_1POXr3Lyen3md8eTTqxFWs4u',
        price: 18999,
        noTermPrice: 19999,
        // noTermDiscountPrice: 16999,
        speed: {
          download: 4000,
          upload: 4000,
        },
        hyper: true,
        gaugeFill: 100,
        householdSize: 11,
        promo: {
          bbc: {
            price: 9499,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
          signup: {
            price: 9499,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          '5month': {
            price: 9499,
            stripePriceCode: '',
            benefit: '5 months half price',
            description: 'Half price for the first 5 months*',
          },
          '6month': {
            price: 9499,
            stripePriceCode: '',
            benefit: '6 months half price',
            description: 'Half price for the first 6 months*',
          },
        },
      },
    ],
    supportPlans: [
      {
        id: 1,
        name: 'Digital Self Service',
        price: 0,
      },
      {
        id: 2,
        name: 'Premium Agent Support',
        monthsFree: 1,
        price: 1999, // if signed up from the dashboard
        stripePriceCode: inProduction ? 'price_1MWpYYLyen3md8eTnzaoB4Md' : 'price_1MWpuSLyen3md8eTiz0HwKd3',

        signupPrice: 1499, // if signed up during signup
        stripeSignupPriceCode: inProduction ? 'price_1LtiPELyen3md8eTLs5CiWOl' : 'price_1LtiRWLyen3md8eTBekkXrAZ',

        stripeCode: inProduction ? 'prod_McyLOxQ6mAZ6UE' : 'prod_McyO20Z3gvWyG6',
      },
    ],
    terms: [
      {
        id: 1,
        name: '12 months',
        nameSummary: '12 months term',
        benefit: '[duration] months half price',
        signupPrice: true,
        noTermPrice: false,
        contract: '12',
      },
      {
        id: 2,
        name: 'No-term',
        nameSummary: 'No-term',
        benefit: 'Cancel anytime with no fees.',
        discountBenefit: '15% discount for Student Card',
        signupPrice: false,
        noTermPrice: true,
        contract: 'no-term',
        contractDiscount: 'no-term-15',
        noTermDiscount: true,
      },
    ],

    setup: {
      id: 1,
      name: 'Setup Fee',
      price: 3499,
      stripeCode: inProduction ? 'prod_On1yQBtEBAnXQj' : 'prod_On1uyaDSVgYyEf',
      stripePriceCode: inProduction ? 'price_1NzRuDLyen3md8eT19YrAED1' : 'price_1NzRr4Lyen3md8eTpaBQhMek',
      immediateCharge: true,
    },
    setupPromo: {
      id: 1,
      name: 'Setup Fee',
      price: 100,
      stripeCode: inProduction ? 'prod_On1yQBtEBAnXQj' : 'prod_On1uyaDSVgYyEf',
      stripePriceCode: inProduction ? 'price_1Oy1lDLyen3md8eT1VMzUKzG' : 'price_1Oy1mRLyen3md8eTvXaucyLw',
      immediateCharge: true,
    },
    // setupPromoBbc: {
    //   id: 1,
    //   name: 'Setup Fee',
    //   price: 999,
    //   stripeCode: inProduction ? 'prod_On1yQBtEBAnXQj' : 'prod_On1uyaDSVgYyEf',
    //   stripePriceCode: inProduction ? 'price_1PnY17Lyen3md8eTDLrBaDdf' : 'price_1PnY0JLyen3md8eTKG5ccmzV',
    //   immediateCharge: true,
    // },
    delivery: {
      id: 1,
      name: 'Delivery Fee',
      price: 1499,
      stripeCode: inProduction ? 'prod_On1z7K5E6Ssm5K' : 'prod_On1of9ozKcgGCq',
      stripePriceCode: inProduction ? 'price_1NzRviLyen3md8eTREveowrd' : 'price_1NzRksLyen3md8eTRXI2BXne',
      immediateCharge: true,
    },
    bond: {
      id: 1,
      name: 'Bond',
      price: 5999,
      stripeCode: inProduction ? 'prod_OFkLW7DBrL1eyo' : 'prod_OFkNqevYjSPfic',
      stripePriceCode: inProduction ? 'price_1NZoNqLyen3md8eTqhrCdahx' : 'price_1O0CMULyen3md8eTPo0bt4E8',
      immediateCharge: true,
    },
    voice: [
      {
        id: 1,
        name: 'Unlimited local',
        description: 'Unlimited local calls including diversions and voicemail',
        slug: 'local',
        price: 1099,
        stripeCode: inProduction ? '' : '',
        stripePriceCode: inProduction ? '' : '',
      },
      {
        id: 2,
        name: 'Unlimited local, national and mobile',
        description: 'Unlimited local, national and mobile calling',
        slug: 'nz',
        price: 1399,
        stripeCode: inProduction ? '' : '',
        stripePriceCode: inProduction ? '' : '',
      },
      {
        id: 3,
        name: 'Unlimited local, national, mobile and AU',
        description: 'Unlimited calls in New Zealand and Australia',
        slug: 'nzau',
        price: 1699,
        stripeCode: inProduction ? '' : '',
        stripePriceCode: inProduction ? '' : '',
      },
    ],

    discounts: {
      referralDiscount: {
        name: 'Referral Discount',
        discount: 0.5,
        // price: -2000,
      },
      signupDiscount: {
        name: 'Signup Discount',
        discount: 0.5,
      },
    },
  };
};

export default productList;
