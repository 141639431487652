import { defineStore } from 'pinia';
import { type LooseObject, type Nullable } from '@/types/generic';
import { handleError } from '~/utils/errors';
import { useProducts } from '~/stores/products';
import { SupportPlan } from '~/types/products';
import { PendingSubscription, StripeSubscription } from '~/types/subscriptions';
import { useMemberSignup } from '~/stores/signup';

export interface CustomerDetails {
  name: string;
  phone: string;
  plan?: LooseObject;
  referrer?: string;
  promo?: string;
}

export interface Customer {
  id: number;
  // account_id: number;
  auth0_sub: string;
  name: string;
  email: string;
  // dob: string;
  phone: string;
  // marketing: string;
  stripe_id: string;
  created_at: string;
  updated_at: string;
}

export interface SubscriptionsList {
  broadband: StripeSubscription[];
  support: StripeSubscription[];
  pending: PendingSubscription[];
}

interface State {
  user: Nullable<Customer>;
  subscriptions: {
    active: StripeSubscription[];
    pending: PendingSubscription[];
  };
  subscriptionsLoaded: boolean;
  isLoading: boolean;
  supportPlan: number;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: null,
    subscriptions: {
      active: [],
      pending: [],
    },
    subscriptionsLoaded: false,
    isLoading: false,
    supportPlan: 1,
  }),
  getters: {
    customerExists(state) {
      return !!state.user;
    },

    supportPlanProduct(state) {
      const products = useProducts();
      return products.supportPlans.find((plan: SupportPlan) => plan.id === state.supportPlan);
    },

    hasPremiumSupportPlan(state) {
      return state.supportPlan === 2;
    },

    subscriptionsList(state): SubscriptionsList {
      const list: SubscriptionsList = {
        broadband: [],
        support: [],
        pending: [],
      };

      const products = useProducts();

      const supportPlansPriceIds = products.supportPlans.reduce((curr: string[], plan): string[] => {
        if (plan.stripePriceCode) {
          curr.push(plan.stripePriceCode);
        }
        if (plan.stripeSignupPriceCode) {
          curr.push(plan.stripeSignupPriceCode);
        }
        return curr;
      }, []);

      state.subscriptions.active.forEach((subscription) => {
        const isSupport = supportPlansPriceIds.includes(subscription.plan.id);
        // const isSupport = subscription.items.reduce((curr: boolean, item) => {
        //   if (curr) {
        //     return curr;
        //   }
        //   if (supportPlansPriceIds.includes(item.plan.id)) {
        //     return true;
        //   }
        //   return curr;
        // }, false);

        if (isSupport) {
          list.support.push(subscription);
        } else {
          list.broadband.push(subscription);
        }
      });

      list.pending = state.subscriptions.pending;

      return list;
    },

    monthlyCost(state): number {
      if (!state.subscriptions.active.length) {
        return 0;
      }
      const prices: number[] = [];
      state.subscriptions.active.forEach((subscription) => {
        prices.push(subscription.plan.amount);
      });
      return prices.reduce((a, b) => a + b);
    },
  },
  actions: {
    async loadUser(captureLead: boolean = false) {
      if (this.isLoading || this.user) {
        return;
      }
      this.isLoading = true;
      try {
        const accessToken = await this.$auth0.getAccessTokenSilently();
        if (!accessToken) {
          this.isLoading = false;
          this.user = null;
          return;
        }
        const response = await this.$api.get<Customer>('/customer', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.status === 200 || response.status === 201) {
          this.user = response._data.data;
          this.isLoading = false;
        }
      } catch (err: any) {
        // create user if required
        if (err.statusCode === 402 && captureLead) {
          await this.captureLead();
        }
        this.user = null;
        this.isLoading = false;
        // handleError(err);
      }
    },

    async createUser(details: CustomerDetails) {
      const accessToken = await this.$auth0.getAccessTokenSilently();

      const submitData: CustomerDetails = {
        name: details.name,
        phone: details.phone,
      };

      if (details.plan) {
        submitData.plan = details.plan;
      }
      if (details.promo) {
        submitData.promo = details.promo;
      }
      if (details.referrer) {
        submitData.referrer = details.referrer;
      }

      try {
        const response = await this.$api.post<Customer, CustomerDetails>('/customer', submitData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200 || response.status === 201) {
          this.user = response._data.data;
        }
      } catch (err) {
        this.user = null;
        handleError(err);
      }
    },

    async updateUser(details: CustomerDetails) {
      const accessToken = await this.$auth0.getAccessTokenSilently();

      if (!this.customerExists) {
        return this.createUser(details);
      }

      const submitData: CustomerDetails = {
        name: details.name,
        phone: details.phone,
      };

      if (details.plan) {
        submitData.plan = details.plan;
      }
      if (details.promo) {
        submitData.promo = details.promo;
      }
      if (details.referrer) {
        submitData.referrer = details.referrer;
      }

      try {
        const response = await this.$api.put<Customer, CustomerDetails>('/customer', submitData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200 || response.status === 201) {
          this.user = response._data.data;
        }
        return true;
      } catch (err) {
        this.user = null;
        handleError(err);
      }
      return false;
    },

    async captureLead(): Promise<boolean> {
      const accessToken = await this.$auth0.getAccessTokenSilently();
      if (this.customerExists) {
        return true;
      }
      try {
        const signup = useMemberSignup();

        const submitData = signup.signupDetails.referrer ? { referrer: signup.signupDetails.referrer } : {};

        const response = await this.$api.post('/customer/capture', submitData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200 || response.status === 201) {
          return true;
        }
      } catch (err) {
        handleError(err);
      }
      return false;
    },

    async fetchSubscriptions() {
      if (this.subscriptionsLoaded) {
        return;
      }
      this.isLoading = true;
      const accessToken = await this.$auth0.getAccessTokenSilently();

      try {
        const response = await this.$api.get<LooseObject[]>('/customer/subscriptions', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          this.subscriptions = response._data.data || [];
          this.subscriptionsLoaded = true;
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        handleError(err);
      }
    },
  },
});
